import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import { getImageByPath } from '../../utils/image'
import Img from 'gatsby-image'

import './OffalCards.scss'
import { FluidObject } from 'gatsby-image'
import { OffalCardsProps } from '.'

const OffalCards: React.FunctionComponent<OffalCardsProps> = ({
  cards,
  subtitle,
  toggleHandler,
  edges,
}): JSX.Element => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h3 className="product-detailed__subtitle">{subtitle}</h3>
        </Col>
      </Row>
      <Row>
        {cards.map((card, i) => {
          card.image = getImageByPath<FluidObject>(edges, card.path)
          return (
            <Col key={i} xs={3}>
              <div className="meat-card">
                <div className="meat-card__image">
                  <Img fluid={card.image} />
                </div>
                <div className="meat-card__details">
                  <p className="meat-card__code">{card.code}</p>
                  <h4 className="meat-card__name">{card.name}</h4>
                  <p className="meat-card__description">{card.description}</p>
                </div>
                <button
                  className="meat-card__button primary-button"
                  onClick={() => toggleHandler(card)}
                >
                  Send inquiry
                </button>
              </div>
            </Col>
          )
        })}
      </Row>
    </React.Fragment>
  )
}

export default OffalCards
