import { FormFieldType } from '../FormField'

export const fields = [
  {
    type: FormFieldType.email,
    placeholder: 'Email*',
    name: 'email',
    required: true,
    validator: new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$', 'i'),
    errorText: 'Invalid email',
  },
  {
    type: FormFieldType.text,
    placeholder: 'Country*',
    name: 'country',
    required: true,
    validator: new RegExp('^[\u0400-\u04FFa-z\\s]+$', 'i'),
    errorText: 'Invalid country',
  },
  { type: FormFieldType.textarea, placeholder: 'Message', name: 'message' },
]
