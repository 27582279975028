import * as React from 'react'
import Img from 'gatsby-image'
import { Formik } from 'formik'
import * as classNames from 'classnames'

import { fields } from './fields'
import validateForm from '../../utils/validate'
import FormField from '../FormField/FormField'

import { InquiryFormProps } from '.'

import './InquiryForm.scss'

const InquiryForm: React.FunctionComponent<InquiryFormProps> = ({
  meat,
  redirectTo,
  showMeat,
}): JSX.Element => {
  const formEl: React.MutableRefObject<any> = React.useRef(null)
  const { code, name, description } = meat

  return (
    <Formik
      initialValues={{
        email: '',
        country: '',
        message: '',
        code,
        name,
        description,
        _next: redirectTo,
      }}
      validate={validateForm(fields)}
      onSubmit={() => {
        formEl.current.submit()
      }}
    >
      {({ values, handleChange, handleSubmit, errors, touched }: any) => {
        return (
          <form
            className="inquiry-form"
            ref={formEl}
            action={`https://formspree.io/${process.env.GATSBY_EMAIL_TARGET}`}
            method="POST"
            noValidate
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="_next" value={values._next} />
            <input type="hidden" name="_subject" value="Inquiry Form" />
            <input type="text" name="_gotcha" style={{ display: 'none' }} />

            {/* <h4 className="inquiry-form__title">Send Inquiry</h4>
            <p className="inquiry-form__subtitle">
              Send us your email and we will contact you
            </p> */}
            {['code', 'name', 'description'].map(item => (
              <input
                name={item}
                type="hidden"
                value={values[item]}
                key={item}
              />
            ))}

            {showMeat && meat && (
              <div className="selected-meat__details">
                <div className="selected-meat__image">
                  <Img fluid={meat.image} />
                </div>
                <div className="selected-meat__info">
                  <p className="selected-meat__code">{meat.code}</p>
                  <h4 className="selected-meat__name">{meat.name}</h4>
                </div>
              </div>
            )}

            {fields.map((field, i) => (
              <FormField
                key={i}
                {...field}
                value={values[field.name]}
                onChange={handleChange}
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            ))}
            <button
              className={classNames('primary-button', {
                'full-width': showMeat,
              })}
              type="submit"
            >
              Send
            </button>
          </form>
        )
      }}
    </Formik>
  )
}

InquiryForm.defaultProps = {
  redirectTo: `//${process.env.GATSBY_DOMAIN}`,
  showMeat: false,
}

export default InquiryForm
