import * as React from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'

import { beefOffal, sheepOffal } from '../../../dataMocks/offalCuts'
import { graphql } from 'gatsby'

import Layout from '../../../components/Layout/Layout'
import ModalHeader from '../../../components/ModalWindow/ModalHeader/ModalHeader'
import InquiryForm from '../../../components/InquiryForm/InquiryForm'
import OffalCards from '../../../components/OffalCards/OffalCards'

const offalCards = [
  {
    subtitle: 'Beef Offal',
    cards: beefOffal,
  },
  {
    subtitle: 'Sheep Offal',
    cards: sheepOffal,
  },
]

class ProductOffal extends React.Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      selected: null,
      showModal: false,
    }
  }

  toggleModal = (card = {}) => {
    this.setState(({ selected, showModal }) => ({
      selected: card,
      showModal: !showModal,
    }))
  }

  render() {
    const {
      data: {
        allFile: { edges },
      },
    } = this.props
    const { selected, showModal } = this.state

    return (
      <Layout seo={{ title: 'Offal' }}>
        <section className="product-detailed">
          <Container>
            <Row>
              <Col>
                <p className="product-detailed__info">
                  Please click on any item to send us an inquiry
                </p>
              </Col>
            </Row>
            {offalCards.map((card, i) => (
              <OffalCards
                key={i}
                subtitle={card.subtitle}
                edges={edges}
                cards={card.cards}
                toggleHandler={this.toggleModal}
              />
            ))}
          </Container>
          <Modal
            centered
            show={showModal}
            className="app-modal  app-modal--small"
            onHide={() => this.toggleModal()}
          >
            <ModalHeader
              onClose={() => this.toggleModal()}
              title="Send Inquiry"
              subtitle="Send us your email and we will contact you"
            />

            <Modal.Body>
              <InquiryForm meat={selected} showMeat={true} />
            </Modal.Body>
          </Modal>
        </section>
      </Layout>
    )
  }
}

export default ProductOffal

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { regex: "/product-detailed/offal/" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 215, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
