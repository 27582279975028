export const beefOffal = [
  {
    path: 'product-detailed/offal/6000.png',
    image: undefined,
    code: '6000',
    name: 'Tongue - Long cut',
    description: '',
  },
  {
    path: 'product-detailed/offal/6010.png',
    image: undefined,
    code: '6010',
    name: 'Tongue - Short cut',
    description: '',
  },
  {
    path: 'product-detailed/offal/6020.png',
    image: undefined,
    code: '6020',
    name: 'Tongue short cut - Special trim',
    description: '',
  },
  {
    path: 'product-detailed/offal/6025.png',
    image: undefined,
    code: '6025',
    name: 'Tongue - Swiss cut special trim',
    description: '',
  },
  {
    path: 'product-detailed/offal/6030.png',
    image: undefined,
    code: '6030',
    name: 'Tongue - Swiss cut',
    description: '',
  },
  {
    path: 'product-detailed/offal/6040.png',
    image: undefined,
    code: '6040',
    name: 'Tongue root',
    description: '',
  },
  {
    path: 'product-detailed/offal/6045.png',
    image: undefined,
    code: '6045',
    name: 'Tongue root fillet',
    description: '',
  },
  {
    path: 'product-detailed/offal/6050.png',
    image: undefined,
    code: '6050',
    name: 'Cheek - Papillae on',
    description: '',
  },
  {
    path: 'product-detailed/offal/6060.png',
    image: undefined,
    code: '6060',
    name: 'Cheek - Papillae off',
    description: '',
  },
  {
    path: 'product-detailed/offal/6070.png',
    image: undefined,
    code: '6070',
    name: 'Tail',
    description: '',
  },
  {
    path: 'product-detailed/offal/6080.png',
    image: undefined,
    code: '6080',
    name: 'Liver',
    description: '',
  },
  {
    path: 'product-detailed/offal/6090.png',
    image: undefined,
    code: '6090',
    name: 'Kidney',
    description: '',
  },
  {
    path: 'product-detailed/offal/6100.png',
    image: undefined,
    code: '6100',
    name: 'Heart',
    description: '',
  },
  {
    path: 'product-detailed/offal/6110.png',
    image: undefined,
    code: '6110',
    name: 'Thymus gland',
    description: '',
  },
  {
    path: 'product-detailed/offal/6120.png',
    image: undefined,
    code: '6120',
    name: 'Brain',
    description: '',
  },
  {
    path: 'product-detailed/offal/6140.png',
    image: undefined,
    code: '6140',
    name: 'Tripe - Raw unscalded',
    description: '',
  },
  {
    path: 'product-detailed/offal/6141.png',
    image: undefined,
    code: '6141',
    name: 'Rumen pillars',
    description: '',
  },
  {
    path: 'product-detailed/offal/6150.png',
    image: undefined,
    code: '6150',
    name: 'Tripe (Scalded)',
    description: '',
  },
  {
    path: 'product-detailed/offal/6151.png',
    image: undefined,
    code: '6151',
    name: 'Tripe pieces (Raw un-scalded)',
    description: '',
  },
  {
    path: 'product-detailed/offal/6152.png',
    image: undefined,
    code: '6152',
    name: 'Tripe - Honeycomb',
    description: '',
  },
  {
    path: 'product-detailed/offal/6153.png',
    image: undefined,
    code: '6153',
    name: 'Tripe pieces (Scalded)',
    description: '',
  },
  {
    path: 'product-detailed/offal/6180.png',
    image: undefined,
    code: '6180',
    name: 'Testes',
    description: '',
  },
  {
    path: 'product-detailed/offal/6190.png',
    image: undefined,
    code: '6190',
    name: 'Spleen',
    description: '',
  },
  {
    path: 'product-detailed/offal/6200.png',
    image: undefined,
    code: '6200',
    name: 'Tendons',
    description: '',
  },
  {
    path: 'product-detailed/offal/6210.png',
    image: undefined,
    code: '6210',
    name: 'Lungs',
    description: '',
  },
  {
    path: 'product-detailed/offal/6221.png',
    image: undefined,
    code: '6221',
    name: 'Body fat',
    description: '',
  },
  {
    path: 'product-detailed/offal/6222.png',
    image: undefined,
    code: '6222',
    name: 'Neck bone',
    description: '',
  },
  {
    path: 'product-detailed/offal/6240.png',
    image: undefined,
    code: '6240',
    name: 'Head meat',
    description: '',
  },
  {
    path: 'product-detailed/offal/6260.png',
    image: undefined,
    code: '6260',
    name: 'Lips',
    description: '',
  },
  {
    path: 'product-detailed/offal/6270.png',
    image: undefined,
    code: '6270',
    name: 'Diaphragm',
    description: '',
  },
  {
    path: 'product-detailed/offal/6273.png',
    image: undefined,
    code: '6273',
    name: 'Ligamentum nuchae',
    description: '',
  },
  {
    path: 'product-detailed/offal/6496.png',
    image: undefined,
    code: '6496',
    name: 'Small intestine',
    description: '',
  },
  {
    path: 'product-detailed/offal/6497.png',
    image: undefined,
    code: '6497',
    name: 'Large intestine',
    description: '',
  },
  {
    path: 'product-detailed/offal/6499E.png',
    image: undefined,
    code: '6499E',
    name: 'Beef bone',
    description: '',
  },
  {
    path: 'product-detailed/offal/6499M.png',
    image: undefined,
    code: '6499M',
    name: 'Feet',
    description: '',
  },
]

export const sheepOffal = [
  {
    path: 'product-detailed/offal/7000.png',
    image: undefined,
    code: '7000',
    name: 'Tongue - Long cut',
    description: '',
  },
  {
    path: 'product-detailed/offal/7010.png',
    image: undefined,
    code: '7010',
    name: 'Tongue - Short cut',
    description: '',
  },
  {
    path: 'product-detailed/offal/7025.png',
    image: undefined,
    code: '7025',
    name: 'Tongue root',
    description: '',
  },
  {
    path: 'product-detailed/offal/7030.png',
    image: undefined,
    code: '7030',
    name: 'Liver',
    description: '',
  },
  {
    path: 'product-detailed/offal/7040.png',
    image: undefined,
    code: '7040',
    name: 'Kidney',
    description: '',
  },
  {
    path: 'product-detailed/offal/7050.png',
    image: undefined,
    code: '7050',
    name: 'Heart',
    description: '',
  },
  {
    path: 'product-detailed/offal/7060.png',
    image: undefined,
    code: '7060',
    name: 'Thymus gland',
    description: '',
  },
  {
    path: 'product-detailed/offal/7070.png',
    image: undefined,
    code: '7070',
    name: 'Brain',
    description: '',
  },
  {
    path: 'product-detailed/offal/7080.png',
    image: undefined,
    code: '7080',
    name: 'Tripe (Scalded)',
    description: '',
  },
  {
    path: 'product-detailed/offal/7090.png',
    image: undefined,
    code: '7090',
    name: 'Spleen',
    description: '',
  },
  {
    path: 'product-detailed/offal/7091.png',
    image: undefined,
    code: '7091',
    name: 'Tendons',
    description: '',
  },
  {
    path: 'product-detailed/offal/7100.png',
    image: undefined,
    code: '7100',
    name: 'Lungs',
    description: '',
  },
  {
    path: 'product-detailed/offal/7110.png',
    image: undefined,
    code: '7110',
    name: 'Head meat',
    description: '',
  },
  {
    path: 'product-detailed/offal/7140.png',
    image: undefined,
    code: '7140',
    name: 'Pluck',
    description: '',
  },
  {
    path: 'product-detailed/offal/7150.png',
    image: undefined,
    code: '7150',
    name: 'Head',
    description: '',
  },
]
